import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import ProjectList from '../components/ProjectList';
import { decodeEntities } from '../utils/htmlParse';

export default class ProjectIndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { allWordpressWpProjects, categories, wordpressWpSettings } = data;
    const { title: siteTitle } = wordpressWpSettings;
    const { edges: posts } = allWordpressWpProjects

    return (
      <Layout location={location}>
        <SEO title={`Projects | ${decodeEntities(siteTitle)}`} />
        <PageHeader headerTitle="Projects" location={location} />
        <ProjectList
          posts={posts}
          title="Latest projects"
          pageContext={pageContext}
          categories={categories.edges}
          siteMetadata={wordpressWpSettings}
          pathPrefix="projects"
        />
      </Layout>
    )
  }
}

ProjectIndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpProjects: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wordpressWpSettings {
      title,
      wordpressUrl
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
